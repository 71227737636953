/**
 * 공통 함수 모음
 */

import { clearSession, getSession } from "../lib/session";
import { ConfirmContent } from "./type";

// Selector
const logout = document.querySelector("#logout") as HTMLButtonElement;

// State

interface State {
  confirmToggle: boolean;
  modalToggle: boolean;
}

const state: State = {
  confirmToggle: false,
  modalToggle: false,
};

// useEffect
document.addEventListener("DOMContentLoaded", () => {
  if (window.location.pathname === "/") {
    const session = getSession();

    if (session) {
      window.location.replace("/live-list");
    }

    return;
  }

  // 로그아웃 버튼 클릭
  logout?.addEventListener("click", () => {
    clearSession();
    window.location.href = "/";
  });
});

// Methods

/**
 * Confirm Event
 * @param selector
 * @param options
 */
export const confirmHandler = (options: ConfirmContent) => {
  const confirm = document.querySelector(".ql__confirm") as HTMLElement;

  if (!confirm) {
    console.warn(".confirm not found");
    return;
  }

  const title = confirm.querySelector(".confirm__title") as HTMLDivElement;
  const message = confirm.querySelector(".confirm__message") as HTMLDivElement;
  const cancelButton = confirm.querySelector(
    ".confirm__buttons_cancel"
  ) as HTMLDivElement;
  const confirmButton = confirm.querySelector(
    ".confirm__buttons_confirm"
  ) as HTMLDivElement;

  function dismiss() {
    confirm.classList.remove("open");

    setTimeout(function () {
      title.innerText = "";
      message.innerText = "";
      cancelButton.onclick = null;
      confirmButton.onclick = null;
      state.confirmToggle = false;
    }, 300);
  }

  const next = !state.confirmToggle;

  if (next) {
    const isCancel = options.isCancel === undefined ? true : options.isCancel;
    // open
    state.confirmToggle = true;
    title.innerText = options.title || "확인";
    message.innerHTML = options.message;

    cancelButton.style.display = isCancel ? "block" : "none";

    cancelButton.onclick = () => {
      options.onClose && options.onClose();
      dismiss();
    };

    confirmButton.onclick = () => {
      options.onConfirm();
      dismiss();
    };

    confirm.classList.add("open");
  }
};

export const Modal = (selector = ".ql__modal") => {
  const modal = document.querySelector(selector) as HTMLElement;

  if (!modal) return null;

  return {
    open() {
      modal.classList.add("open");
    },
    close() {
      modal.classList.remove("open");
    },
  };
};
